<template>

	<div v-loading="loading" class="customerDetail">
		<div style="display: flex;background: #fff;padding: 15px;align-items: center;">
			<img :src="memberInfo.WxHeadUrl" alt="" style="width: 50px;height: 50px;margin-left: 10px;background: #000000;border-radius: 100%;">
			<div style="margin-left: 20px;font-size: 14px;color: #303133;">昵称：{{memberInfo.WxNickname}}</div>
			<div style="flex:1;display: flex;align-items: center;font-size: 14px;color: #303133;margin-left: 100px;">
				<div style="margin-right:50px;">姓名：{{memberInfo.Name}}</div>
				<div style="margin-right:50px;">手机号：{{memberInfo.Phone}}</div>

				<div style="margin-right:50px;">成为客户时间:{{memberInfo.AddTime}}</div>
				<!-- <buttonPermissions :datas="'chatCustomer'">
					<el-button type="text" @click="contact()">联系客户</el-button>
				</buttonPermissions> -->
			</div>
		</div>
		<div style="background: #fff;padding: 15px;margin-top: 10px;align-items: center;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 10px;width: 100%;display: flex;align-items: center;font-size: 14px;color: #303133;">客户资料
				<buttonPermissions :datas="'editInformation'">
					<el-button type="text" @click="customerInfoEdit()" style="margin-left: 20px;">编辑资料</el-button>
				</buttonPermissions>
			</div>
			<div style="display: flex;font-size: 14px;color: #303133;justify-content: space-between;">
				<div style="margin-top: 15px;">
					<div style="display: flex;">
						<div style="width: 75px;text-align: right;">会员级别：</div>
						<span v-if="memberInfo.VipRoleName">
							<span>{{memberInfo.VipRoleName}}</span>
							<span>({{memberInfo.VipNo}})</span>
						</span>
						<span v-else>--</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 75px;text-align: right;">性别：</div><span>{{memberInfo.GenderValue}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 75px;text-align: right;">归属门店：</div><span>{{memberInfo.VipBelongShopName}}</span>
					</div>
				</div>
				<div style="margin-top: 15px;">
					<div style="display: flex;">
						<div style="width: 105px;text-align: right;">成为会员时间：</div>
						<span v-if="memberInfo.IsVip">
							<span v-if="memberInfo.VipAuthTime == '2000-01-01 00:00:00'">--</span>
							<span v-else>{{memberInfo.VipAuthTime}}</span>
						</span>
						<span v-else>--</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 105px;text-align: right;">地区：</div><span>{{memberInfo.Province}}{{memberInfo.City}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 105px;text-align: right;">锁粉店员：</div>
						<span v-if="memberInfo.LockMallEmployeeId>0">{{memberInfo.LockMallEmployeeName}}({{memberInfo.LockMallEmployeeShopName}})</span>
						<span v-else>--</span>
					</div>
				</div>
				<div style="margin-top:15px;">
					<div style="display: flex;align-items:center;">
						<div style="width: 75px;text-align: right;">推手级别：</div>
						<span v-if="memberInfo.DistributRoleName">{{memberInfo.DistributRoleName}}</span>
						<span v-else>--</span>
						<div style="color: #409EFF; font-size: 14px;margin-left:30px;cursor: pointer;" type="text" @click="Authbutor()"
						 v-if="memberInfo.DistributRoleName==''">授权推手</div>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 75px;text-align: right;">微信号：</div><span>{{memberInfo.WeixinId}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 75px;text-align: right;">锁粉推手：</div>
						<span v-if="memberInfo.FirstFromMemberName || memberInfo.FirstFromMemberPhone">
							<!-- <span></span> -->
							
							<span>{{memberInfo.FirstFromMemberName?memberInfo.FirstFromMemberName:memberInfo.FirstFromMemberPhone}}</span>
							<span v-if='memberInfo.FirstFromMemberPhone' style="margin-left: 4px;margin-right: 4px;">({{memberInfo.FirstFromMemberPhone}})</span>
							<span v-if="!memberInfo.IsEffectiveFirstFromMember">（已失效）</span>
							<!-- <span>{{memberInfo.UnionId}}</span>
							<span v-if="memberInfo.phone">({{memberInfo.phone}})</span> -->
						</span>
						<span v-else>--</span>
					</div>
				</div>
				<div style="margin-top: 15px;margin-right: 100px;">
					<div style="display: flex;">
						<div style="width: 115px;text-align: right;">成为推手时间：</div>
						<span v-if="memberInfo.DistributRoleName">
							<span v-if="memberInfo.MemberDistributorAuthTime=='2000-01-01 00:00:00'">--</span>
							<span v-else>{{memberInfo.MemberDistributorAuthTime}}</span>
						</span>
						<span v-else>--</span>

					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 115px;text-align: right;">生日：</div>
						<span v-if="memberInfo.BirthdayValue=='1900-01-01'">--</span>
						<span v-else>{{memberInfo.BirthdayValue}}</span>
					</div>

					<div style="display: flex;margin-top: 15px;">
						<div style="width: 75px;text-align: right;">微信标识：</div><span>{{memberInfo.UnionId}}</span>
					</div>
				</div>
			</div>
		</div>
		<div style="background: #fff;padding: 15px;margin-top: 10px;align-items: center;font-size: 14px;color: #303133;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 10px;width: 100%;display: flex;align-items: center;font-size: 14px;color: #303133;">客户标签
				<el-button type="text" @click="setLabel" style="margin-left: 20px;">选择标签</el-button>
			</div>
			<div style="margin-top: 20px;display: flex;justify-content: flex-start;flex-wrap: wrap;" class="elTags">
				<el-tag :key="index" v-for="(item,index) in SelectLabels" color="#DCDFE6" closable :disable-transitions="false"
				 style="width:150px;margin-bottom: 30px;margin-right:50px;color: #303133 !important;text-align: center;" @close="handleClose(index)">
					<span class="tagStyle">{{item.Text}}</span>
				</el-tag>
			</div>
		</div>
		<!-- 客户资产 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;align-items: center;font-size: 14px;color: #303133;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 10px;width: 100%;display: flex;align-items: center;font-size: 14px;color: #303133;">客户资产</div>
			<div style="display: flex;font-size: 14px;color: #303133;margin-top: 20px;justify-content: space-between;margin-right: 150px;">
				<div style="display: flex;">
					<div>积分:</div>
					<div style="display: flex;flex-direction: column;font-size: 14px;color: #303133;">
						<div style="color: #409EFF;cursor: pointer;" @click="ScorePage">{{memberInfo.Point}}</div>
						<div style="display: flex;">
							<el-button type="text" @click="AddScore()" style="margin-left: 20px;margin-left: 0px !important;">赠送积分</el-button>
							<el-button type="text" @click="ReduceScore()" style="margin-left: 20px;color: #F56C6C;">扣减积分</el-button>
							<el-button type="text" @click="customerClearPoints()" style="margin-left: 20px;color: #F56C6C;">积分清零</el-button>
						</div>
					</div>
				</div>
				<div style="font-size: 14px;color: #303133;margin-left: 50px;">优惠券:{{memberInfo.CouponCount}}（已使用:{{memberInfo.UseCouponCount}}）</div>
				<div style="font-size: 14px;color: #303133;margin-left: 50px;cursor: pointer;" @click="showCashPop">累计返现:<span
					 style="color: #409EFF;">{{memberInfo.CashbackMoneyAll}}</span></div>
				<div style="font-size: 14px;color: #303133;margin-left: 50px;">累计收益（含待结算）:<span style="color: #409EFF;">{{memberInfo.DrawBalanceAll}}</span></div>
			</div>
		</div>

		<!-- 会员权益 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;align-items: center;font-size: 14px;color: #303133;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 10px;width: 100%;display: flex;align-items: center;font-size: 14px;color: #303133;">会员权益</div>
			<div class="Isvip" v-if="memberInfo.IsVip">
				<div style="display: flex;font-size: 14px;color: #303133;align-items: center;margin-top: 20px;">
					会员权益:
					<div style="background: #DCDFE6;width: 145px;height: 50px;text-align: center;line-height: 50px;margin-left: 20px;"
					 v-if="memberInfo.VipDiscountValue">
						消费{{memberInfo.VipDiscountValue}}折
					</div>
					<div style="background: #DCDFE6;width: 145px;height: 50px;text-align: center;line-height: 50px;margin-left: 20px;"
					 v-if="memberInfo.IsFreeExpress">
						包邮
					</div>
					<div style="background: #DCDFE6;width: 145px;height: 50px;text-align: center;line-height: 50px;margin-left: 20px;"
					 v-if="memberInfo.PointMultiple">
						积分{{memberInfo.PointMultiple}}倍
					</div>
				</div>
				<div style="display: flex;font-size: 14px;color: #303133;align-items: center;margin-top: 20px;">
					升级礼包:
					<div style="background: #DCDFE6;width: 145px;height: 50px;text-align: center;line-height: 50px;margin-left: 20px;"
					 v-if="memberInfo.CurrentAuthGivePoint">
						赠送{{memberInfo.CurrentAuthGivePoint}}积分
					</div>
					<div style="background: #DCDFE6;width: 145px;height: 50px;text-align: center;line-height: 50px;margin-left: 20px;"
					 v-if="memberInfo.MemberCouponCount">
						赠送{{memberInfo.MemberCouponCount}}张优惠券
					</div>
					<div style="background: #DCDFE6;width: 145px;height: 50px;text-align: center;line-height: 50px;margin-left: 20px;"
					 v-if="memberInfo.MemberGiftCount">
						赠送{{memberInfo.MemberGiftCount}}个赠品
					</div>
				</div>
			</div>
			<div v-else>未开通会员级别与权益</div>

		</div>

		<div style="background: #fff;padding: 15px;margin-top: 10px;align-items: center;margin-bottom: 100px;">
			<!-- @click="customerInfoEdit()" -->
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 10px;width: 100%;display: flex;align-items: center;font-size: 14px;color: #303133;">消费行为
				<el-button type="text" style="margin-left: 20px;" @click="CustomerDetail">消费明细</el-button>
			</div>
			<div style="display: flex;font-size: 14px;color: #303133;justify-content: space-between;margin-right: 600px;">
				<div style="margin-top: 15px;">
					<div style="display: flex;">
						<div style="width: 130px;text-align: right;">最近一次浏览时间：</div><span>{{memberInfo.LastAccessTime}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 130px;text-align: right;">累计支付次数：</div><span>{{memberInfo.PayCount}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 130px;text-align: right;">累计退款次数：</div><span>{{memberInfo.SupportCount}}</span>
					</div>
				</div>
				<div style="margin-top: 15px;">
					<div style="display: flex;">
						<div style="width: 130px;text-align: right;">最近一次支付时间：</div><span>{{memberInfo.LastPayTime}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 130px;text-align: right;">累计支付金额：</div><span>{{memberInfo.PayMoney}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 130px;text-align: right;">累计退款金额：</div><span>{{memberInfo.SupportMoney}}</span>
					</div>
				</div>
				<div style="margin-top: 15px;">
					<div style="display: flex;align-items: center;">
						<div style="width: 130px;text-align: right;">最近一次支付金额：</div><span>{{memberInfo.LastPayMoney}}</span>
					</div>
					<div style="display: flex;margin-top: 15px;">
						<div style="width: 130px;text-align: right;">笔单价：</div><span>{{memberInfo.AverageOrderPriceShow}}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //设置标签 -->
		<el-dialog title="选择标签" :visible.sync="Labelvisi" width="800px" v-loading="Setlabeloading">
			<div class="selectBox displayCommon" style="width: 92%;margin: 0px auto;">
				<div class="selectItem" v-for="(item,index) in LabelNames" :key="index">
					<el-checkbox v-model="item.checked" class="checkI" @change="ChangeState(item)"></el-checkbox>
					<span>{{item.MemberTagName}}</span>
				</div>
			</div>
			<div style="text-align: center;margin-top: 20px;">
				<el-button style="width: 120px;" @click="Labelvisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SavememberLable">确认</el-button>
			</div>

		</el-dialog>

		<!-- //积分清零 -->
		<el-dialog title="积分清零" :visible.sync="Scorezerovisi" width="600px">
			<el-form :model="ScorezeroForm" :rules="Scorezerorules" ref="ScorezeroForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="清零原因:" prop="Cause">
					<el-input type="textarea" :rows="5" v-model="ScorezeroForm.Cause" style="width: 350px;"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="Scorezerovisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="ZeroSure('ScorezeroForm')">确认</el-button>
			</div>

		</el-dialog>

		<!-- 确认弹窗提示 -->
		<el-dialog :visible.sync="ShowSureAgain" width="350px" center :show-close="false">
			<div style="line-height: 20px;">此次编辑，您将客户等级修改为VIP1[级别名称]；归属门店修改为[门店名称]；锁粉店员修改为[张三（店员所有门店名称）]
				请确认上述修改信息无误</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 100px;">关 闭</el-button>
				<el-button type="primary" style="width: 100px;margin-left: 30px;">确认修改</el-button>
			</span>
		</el-dialog>

		<!-- //赠送积分 -->
		<el-dialog title="赠送积分" :visible.sync="Scorevisi" width="600px">
			<el-form :model="ScoreForm" :rules="Scorerules" ref="ScoreForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="赠送数量:" prop="Num">
					<el-input v-model="ScoreForm.Num" style="width: 400px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"></el-input>
				</el-form-item>
				<el-form-item label="赠送原因:" prop="Cause">
					<el-input type="textarea" v-model="ScoreForm.Cause" :rows="3" style="width: 400px;" maxlength="150"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="Scorevisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="submitaddSure('ScoreForm')" :loading="Addscoreloading">确认赠送</el-button>
			</div>
		</el-dialog>

		<!-- //扣减积分 -->
		<el-dialog title="扣减积分" :visible.sync="Reducevisi" width="650px">
			<el-form :model="ReduceForm" :rules="Reducerules" ref="ReduceForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="扣减数量:" prop="Num">
					<el-input v-model="ReduceForm.Num" style="width: 400px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"></el-input>
				</el-form-item>
				<el-form-item label="扣减原因:" prop="Cause">
					<el-input type="textarea" :rows="3" v-model="ReduceForm.Cause" style="width: 400px;" maxlength="150"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="Reducevisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="ReduceSure('ReduceForm')" :loading="Reducscoreloading">确认扣减</el-button>
			</div>

		</el-dialog>
		<!-- //编辑资料 -->
		<el-dialog title="编辑资料" :visible.sync="UserInfoVisi" width="600px" class="eidt_info">
			<el-form :model="UserInfoForm" :rules="userRules" ref="UserInfoForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="性别:">
					<el-select v-model="UserInfoForm.Sex" placeholder="请选择">
						<el-option v-for="item in SexList" :key="item.Id" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="地区:">
					<el-cascader style="width: 300px;" :props="props" :options="wePlacelist" clearable @change="handleAddressChange"
					 v-model="AddressArr" />
				</el-form-item>
				<!-- <el-form-item label="姓名:" prop="userName">
					<el-input v-model="UserInfoForm.userName" maxlength="20" placeholder="最多输入20个汉字"></el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="userPhone">
					<el-input v-model="UserInfoForm.userPhone" maxlength="11" placeholder="请输入手机号"></el-input>
				</el-form-item> -->
				<el-form-item label="生日:" prop="Birthday">
					<el-date-picker v-model="UserInfoForm.Birthday" type="date" placeholder="选择日期" style="width: 300px;" value-format="yyyy-MM-dd"
					 format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="微信号:">
					<el-input v-model="UserInfoForm.WxId" style="width: 300px;" placeholder="请输入微信号"></el-input>
				</el-form-item>
				<el-form-item label="修改客户等级:" prop="VipLevel">
					<el-select v-model="UserInfoForm.VipLevel" placeholder="请选择">
						<el-option v-for="item in VipLevelist" :key="item.Value" :label="item.Text" :value="item.Value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="修改归属门店:" prop="VipDoor">
					<el-select v-model="UserInfoForm.VipDoor" placeholder="请选择">
						<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="修改锁粉店员:" prop="VipLockCustomer">
					<div style="display: flex;">
						<div>
							<el-input v-model="UserInfoForm.VipLockCustomer" style="width: 300px;" maxlength="11" clearable @clear="clearAddmember(1)"></el-input>
							<div v-if="UserInfoForm.LockMallEmployeeId>0" style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.EmployeeName}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.ShopNameShow}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsSearch' style="color:#E51C23;">查询不到相关店员数据，请确认输入手机号无误</div>

						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(2)">查询</el-button>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="UserInfoVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveUserInfo('UserInfoForm')" :loading="SureAddloading">确认修改</el-button>
			</div>

		</el-dialog>
		<!-- //授权推手 -->
		<el-dialog title="授权推手" :visible.sync="DistrimemVisi" width="600px" class="eidt_info" v-loading="Distrimemloading">
			<el-form :model="DistrimemForm" :rules="DistrimemRules" ref="DistrimemForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="姓名:" prop="userName">
					<el-input v-model="DistrimemForm.userName" maxlength="20" placeholder="最多输入20个字" :disabled="originNameValue!=''"></el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="userPhone">
					<el-input v-model="DistrimemForm.userPhone" maxlength="11" placeholder="请输入手机号" :disabled="originPhoneValue!=''"></el-input>
				</el-form-item>
				<el-form-item label="推手等级:" prop="Distrilevel">
					<el-select v-model="DistrimemForm.Distrilevel" placeholder="请选择">
						<el-option v-for="item in Distrilevelist" :key="item.Id" :label="item.DistributRoleName" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="推荐人:">
					<div style="display: flex;">
						<div>
							<el-input v-model="DistrimemForm.Recomend" style="width: 300px;" maxlength="11" placeholder="输入推荐人手机号，点击右侧查询"></el-input>
							<div v-if='RecomendInfo.EmployeeName || RecomendInfo.WxHeadUrl || RecomendInfo.DistributRoleName' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="RecomendInfo.WxHeadUrl?RecomendInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{RecomendInfo.EmployeeName}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{RecomendInfo.DistributRoleName}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsrecomendSearch' style="color:#E51C23;">查询不到相关推手信息</div>

						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchRecomend">查询</el-button>
					</div>
				</el-form-item>

				<el-form-item label="锁粉店员:" prop="VipLockCustomer">
					<div style="display: flex;">
						<div>
							<el-input v-model="DistrimemForm.VipLockCustomer" style="width: 300px;" maxlength="11" clearable @clear='clearAddmember(2)'></el-input>
							<div v-if='DistrimemForm.LockMallEmployeeId>0' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.EmployeeName}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.ShopNameShow}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsSearch' style="color:#E51C23;">查询不到相关店员数据，请确认输入手机号无误</div>

						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(4)">查询</el-button>
					</div>
				</el-form-item>
				<!-- <el-form-item label="微信标识:">
					<el-input v-model="DistrimemForm.WxId" style="width: 300px;" placeholder="请在客户详情页中复制该推手的微信标识，并粘贴在此处" :disabled="originWeixinId!=''"></el-input>
				</el-form-item> -->



			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="DistrimemVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveDistriInfo('DistrimemForm')" :loading="SaveDistriloading">确认添加</el-button>
			</div>

		</el-dialog>

		<el-dialog title="订单返现" :visible.sync="isShowCashPop" width="800px" v-loading="ShowCashloading">
			<div style="margin-bottom:10px;">累计返现：{{memberInfo.CashbackMoneyAll}}</div>
			<div class="table-container">
				<el-table :data="cashData" style="width: 100%;">
					<el-table-column prop="CashbackMoney" label="返现金额"></el-table-column>
					<el-table-column label="订单" width="250px">
						<template slot-scope="scope">
							<div style="color: #409EFF;font-size: 14px;">{{scope.row.OrderNo}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="返现时间"></el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[5,10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index';
	import axios from 'axios'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		MemberdetailInfo,
		SearchLockEmployee,
		SaveEmployeenInfo,
		SaveauthDistributor,
		MemberUserlevels,
		MemberInit,
		authDistributorInit,
		filterBoxList,
		SingelChangetags,
		MemberTaglists,
		Changesingelpoint,
		EmptyPoint,
		cashbackOrderList,
		findRecommendMember
	} from '@/api/TurnTomySelf'
	import {
		XJChatGetLoginCert
	} from '@/api/login.js'
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				defaultHeader: config.DEFAULT_HEADER,
				ShowCashloading: false,
				Reducscoreloading: false,
				Addscoreloading: false,
				Labelvisi: false,
				Setlabeloading: false,
				LabelNames: [],
				SelectLabels: [],
				originNameValue: '',
				originPhoneValue: '',
				originWeixinId: '',
				Distrimemloading: false,
				SaveDistriloading: false,
				RecomendInfo: {
					EmployeeName: '',
					WxHeadUrl: '',
					DistributRoleName: '',
				},
				//推手信息
				Distrilevelist: [],
				IsrecomendSearch: false,
				DistrimemVisi: false,
				DistrimemForm: {
					userName: '',
					userPhone: '',
					Distrilevel: '',
					Recomend: '',
					RecomendId: '',
					VipLockCustomer: '',
					LockMallEmployeeId: '',
					WxId: '',
				},
				DistrimemRules: {
					userName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					userPhone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, ],
					Distrilevel: [{
						required: true,
						message: '请选择推手级别',
						trigger: 'change'
					}, ],
				},
				//修改推手
				loading: false,
				SureAddloading: false,
				IsSearch: false,
				EmployneeInfo: {
					EmployeeName: '',
					WxHeadUrl: '',
					ShopNameShow: '',
				}, //锁粉信息
				wePlacelist: [],
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				storeList: [],
				SexList: [{
					Id: 1,
					Name: '男'
				}, {
					Id: 2,
					Name: '女'
				}, {
					Id: 3,
					Name: '保密'
				}],
				UserInfoVisi: false,
				UserInfoForm: {
					// userName: '',
					// userPhone: '',
					Sex: '',
					ProvinceId: '',
					CityId: '',
					Province: '',
					City: '',
					Birthday: '',
					WxId: '',
					VipLevel: '',
					VipDoor: '',
					VipLockCustomer: '',
					LockMallEmployeeId: '',
				},
				AddressArr: [],
				VipLevelist: [],
				userRules: {
					userName: [{
						required: true,
						message: '请输入会员姓名',
						trigger: 'blur'
					}, ],
					userPhone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, ],
					Birthday: [{
						required: true,
						message: '请选择生日',
						trigger: 'change'
					}],
					VipLevel: [{
						required: true,
						message: '请选择客户等级',
						trigger: 'change'
					}, ],
					VipDoor: [{
						required: true,
						message: '请选择归属门店',
						trigger: 'change'
					}, ],
					// 					VipLockCustomer: [{
					// 						required: true,
					// 						message: '请输入锁粉店员',
					// 						trigger: 'blur'
					// 					}, ]
				},
				MemberId: '', //客户Id
				memberInfo: {},
				form: {
					title: ''
				},
				formRules: {
					title: [{
						required: true,
						message: '请填写清零原因',
						trigger: 'blur'
					}],
				},
				ScorezeroForm: {
					Cause: ''
				},
				Scorezerorules: {
					Cause: [{
						required: true,
						message: '请输入清零原因',
						trigger: 'blur'
					}]
				},
				Scorezerovisi: false,
				//积分数据
				ScoreForm: {
					Num: '',
					Cause: ''
				},
				Scorerules: {
					Num: [{
						required: true,
						message: '请输入赠送数量',
						trigger: 'blur'
					}, ],
					Cause: [{
						required: true,
						message: '请输入赠送原因',
						trigger: 'blur'
					}, ]
				},

				Reducerules: {
					Num: [{
						required: true,
						message: '请输入扣减数量',
						trigger: 'blur'
					}, ],
					Cause: [{
						required: true,
						message: '请输入扣减原因',
						trigger: 'blur'
					}, ]
				},
				Scorevisi: false,
				Reducevisi: false,
				ReduceForm: {
					Num: '',
					Cause: ''
				},
				ShowSureAgain: false,
				isShowCashPop: false,
				cashData: [],
				currentPage: 1,
				pageSize: 5,
				Total: 1,
				oldPhone: '',
			};
		},
		created() {
			this.getMemberInit()
			this.getDataList() //获取地址数据
			this.getUserlevels() //获取所有会员等级
			this.getMemberTaglists() //获取所有标签名称
			this.getfilterBoxList() //获取推手级别
		},
		beforeMount() {
			//初始化Id
			this.MemberId = this.$route.query.Id
			//初始化
			this.startData()
		},
		methods: {
			clearAddmember(index) {
				if (this.EmployneeInfo.EmployeeName || this.EmployneeInfo.WxHeadUrl || this.EmployneeInfo.ShopNameShow) {
					this.EmployneeInfo.EmployeeName = ''
					this.EmployneeInfo.WxHeadUrl = ''
					this.EmployneeInfo.ShopNameShow = ''
					if (index == 1) {
						this.UserInfoForm.LockMallEmployeeId = 0
					}
					if (index == 2) {
						this.DistrimemForm.LockMallEmployeeId = 0
					}

				}
				if (this.IsSearch) {
					this.IsSearch = false
				}
			},
			//积分跳转
			ScorePage() {
				this.$router.push({
					path: "/Customer/pointsManager",
					query: {
						listState: '1',
						keyWords: this.memberInfo.Phone
					}
				})
			},
			//消费明细
			CustomerDetail() {
				this.$router.push({
					path: "/order/orderMange",
					query: {
						CustomerId: this.MemberId
					}
				})
			},
			//积分清空
			ZeroSure(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								Id: this.MemberId,
								Reason: this.ScorezeroForm.Cause
							}
							let result = await EmptyPoint(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success'
								});
								this.Scorezerovisi = false
								this.startData()
							}

						} catch (err) {

						} finally {

						}
					} else {
						return false;
					}
				});
			},
			async SavememberLable() {
				try {
					let noChecklabel = []
					this.LabelNames.map(item => {
						if (!item.checked) {
							noChecklabel.push(item)
						}
					})
					if (noChecklabel.length == this.LabelNames.length) {
						this.$message({
							showClose: true,
							message: '请选择标签',
							type: 'error'
						});
						return
					}

					let MemberTagIdList = []
					this.LabelNames.map(item => {
						if (item.checked) {
							MemberTagIdList.push(item.Id)
						}
					})
					let data = {
						Id: this.MemberId,
						MemberTagIdList: MemberTagIdList
					}
					let result = await SingelChangetags(data)
					console.log(result, '设置标签的结果')
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							message: '标签设置成功',
							type: 'success'
						});
						this.Labelvisi = false
						this.startData()
					}

				} catch (err) {

				} finally {

				}
			},
			//设置标签
			setLabel() {
				this.Labelvisi = true
				this.LabelNames = this.LabelNames.map(item => {
					item.checked = false
					this.SelectLabels.map(items => {
						if (item.MemberTagName == items.Text) {
							item.checked = true
						}
					})
					return item
				})
			},
			//改变状态值
			ChangeState(record) {
				this.LabelNames = this.LabelNames.map(item => {
					if (item.MemberTagName == record.Text) {
						item.checked = record.checked
					}
					return item
				})
			},
			//授权推手(//初始化授权信息)
			Authbutor() {
				this.DistrimemVisi = true
				this.getauthDistributorInit()
			},
			async getauthDistributorInit() {
				this.Distrimemloading = true
				try {
					let data = {
						Id: this.MemberId
					}
					let result = await authDistributorInit(data)
					if (result.IsSuccess) {
						this.originNameValue = result.Result.NameValue //初始化姓名
						this.originPhoneValue = result.Result.Phone //初始化手机号
						this.originWeixinId = result.Result.UnionId //初始化微信标识
						this.DistrimemForm.userName = result.Result.NameValue
						this.DistrimemForm.userPhone = result.Result.Phone
						this.DistrimemForm.WxId = result.Result.UnionId
						if (result.Result.MemberDistributorRoleId == 0) {
							result.Result.MemberDistributorRoleId = ''
						}
						this.DistrimemForm.Distrilevel = result.Result.MemberDistributorRoleId //分销级别
						if (result.Result.LockMallEmployeePhone) {
							this.DistrimemForm.VipLockCustomer = result.Result.LockMallEmployeePhone // 锁粉手机号
						} else {
							this.DistrimemForm.VipLockCustomer = ''
						}
						this.oldPhone = this.DistrimemForm.VipLockCustomer
						this.DistrimemForm.LockMallEmployeeId = result.Result.LockMallEmployeeId //锁粉Id
						if (this.DistrimemForm.LockMallEmployeeId == 0) {
							this.IsSearch = false
						}
						this.EmployneeInfo.EmployeeName = result.Result.LockMallEmployeeName
						this.EmployneeInfo.WxHeadUrl = result.Result.LockMallEmployeeWxHeadUrl
						this.EmployneeInfo.ShopNameShow = result.Result.LockMallEmployeeShopName
						this.DistrimemForm.RecomendId = result.Result.MemberDistributorRecommendId //推荐人Id
						if (this.DistrimemForm.RecomendId == 0) {
							this.IsrecomendSearch = false
						}
						if (result.Result.MemberDistributorRecommendPhone) {
							this.DistrimemForm.Recomend = result.Result.MemberDistributorRecommendPhone
						} else {
							this.DistrimemForm.Recomend = ''
						}

						this.RecomendInfo.EmployeeName = result.Result.MemberDistributorRecommendName
						this.RecomendInfo.WxHeadUrl = result.Result.MemberDistributorRecommendWxHeadUrl
						this.RecomendInfo.DistributRoleName = result.Result.DistributRoleName

						this.$nextTick(function() {
							this.$refs.DistrimemForm.clearValidate()
						})
					}
				} catch (err) {

				} finally {
					this.Distrimemloading = false
				}
			},
			//初始化数据
			async getMemberInit() {
				this.loading = true
				try {
					let result = await MemberInit()
					this.storeList = result.Result.ShopList
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//保存推手的信息
			SaveDistriInfo(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.SaveDistriloading = true
						try {
							if (this.DistrimemForm.VipLockCustomer != this.oldPhone && this.DistrimemForm.VipLockCustomer != '') {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请点击[查询]确认员工信息'
								});
								return false
							}
							if (this.IsSearch) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请输入正确的锁粉信息'
								});
								return false
							}
							// if (!this.DistrimemForm.LockMallEmployeeId) {
							// 	this.$message({showClose: true,
							// 		type: 'error',
							// 		message: '请输入正确的锁粉信息'
							// 	});
							// 	return false
							// }
							let data = {
								Id: this.MemberId,
								Phone: this.DistrimemForm.userPhone,
								MemberDistributorRoleId: this.DistrimemForm.Distrilevel,
								MemberDistributorRecommendId: this.DistrimemForm.RecomendId,
								LockMallEmployeeId: this.DistrimemForm.LockMallEmployeeId,
								UnionId: this.DistrimemForm.WxId
							}
							let result = await SaveauthDistributor(data)
							if (result.IsSuccess) {
								this.DistrimemVisi = false
								this.startData()
							}
						} catch (err) {

						} finally {
							this.SaveDistriloading = false
						}
					} else {
						return false;
					}
				})

			},
			//推荐人查询
			async SearchRecomend() {

				try {
					console.log(this.DistrimemForm.Recomend, '执行了吗')
					if (this.DistrimemForm.Recomend == '') {
						this.$message({
							showClose: true,
							message: '请输入推荐人信息',
							type: 'error'
						});
						return
					}
					let data = {
						Phone: this.DistrimemForm.Recomend
					}
					let result = await findRecommendMember(data)
					if (result.IsSuccess) {
						this.RecomendInfo = result.Result
						if (JSON.stringify(this.RecomendInfo) == "{}") {
							this.IsrecomendSearch = true
						} else {
							this.IsrecomendSearch = false
						}
						//推荐人Id
						this.DistrimemForm.RecomendId = this.RecomendInfo.Id
					}
				} catch (err) {

				} finally {

				}

			},
			//保存编辑资料
			SaveUserInfo(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.UserInfoForm.VipLockCustomer != this.oldPhone && this.UserInfoForm.VipLockCustomer != '') {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请点击[查询]确认员工信息'
							});
							return false
						}
						if (this.IsSearch) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请输入正确的锁粉信息'
							});
							return false
						}
						// 						if (!this.UserInfoForm.LockMallEmployeeId) {
						// 							this.$message({showClose: true,
						// 								type: 'error',
						// 								message: '请输入正确的锁粉信息'
						// 							});
						// 							return false
						// 						}
						this.$confirm('确认修改客户资料？', '提示', {
							confirmButtonText: '确认修改',
							cancelButtonText: '关闭',
							type: 'warning'
						}).then(
							async () => {
								try {
									let data = {
										Id: this.MemberId,
										Name: this.memberInfo.Name,
										Phone: this.memberInfo.Phone,
										Birthday: this.UserInfoForm.Birthday,
										MemberVipRoleId: this.UserInfoForm.VipLevel,
										VipBelongShopId: this.UserInfoForm.VipDoor,
										WeixinId: this.UserInfoForm.WxId,
										Gender: this.UserInfoForm.Sex,
										ProvinceId: this.UserInfoForm.ProvinceId,
										CityId: this.UserInfoForm.CityId,
										Province: this.UserInfoForm.Province,
										City: this.UserInfoForm.City,
										LockMallEmployeeId: this.UserInfoForm.LockMallEmployeeId
									}
									let result = await SaveEmployeenInfo(data)
									if (result.IsSuccess) {
										this.$message({
											showClose: true,
											type: 'success',
											message: '操作成功!'
										});
										this.UserInfoVisi = false;
										this.startData()
									}
								} catch (err) {
								} finally {
								}
							}

						).catch(() => {
							this.$message({
								showClose: true,
								type: 'info',
								message: '已取消操作'
							});
						});

					}
				})
			},
			//查询锁粉店员
			async SearchLock(index) {
				//1  修改等级里面   2//添加会员的   3//单个操作锁粉店员
				try {
					let data = {}
					//添加会员里面的
					if (index == 2) {
						if (this.UserInfoForm.VipLockCustomer == '') {
							this.$message({
								showClose: true,
								message: '请输入锁粉店员信息',
								type: 'error'
							});
							return
						}
						data = {
							Phone: this.UserInfoForm.VipLockCustomer
						}
					}
					//授权推手里面的
					if (index == 4) {
						if (this.DistrimemForm.VipLockCustomer == '') {
							this.$message({
								showClose: true,
								message: '请输入锁粉店员信息',
								type: 'error'
							});
							return
						}
						data = {
							Phone: this.DistrimemForm.VipLockCustomer
						}
					}

					let result = await SearchLockEmployee(data)
					if (result.IsSuccess) {
						this.EmployneeInfo = result.Result
						if (JSON.stringify(this.EmployneeInfo) == "{}") {
							this.IsSearch = true
						} else {
							this.IsSearch = false
						}
						if (index == 2) {
							this.UserInfoForm.LockMallEmployeeId = this.EmployneeInfo.Id
							this.oldPhone = this.UserInfoForm.VipLockCustomer
						}
						if (index == 4) {
							this.DistrimemForm.LockMallEmployeeId = this.EmployneeInfo.Id
							this.oldPhone = this.DistrimemForm.VipLockCustomer
						}

					}

				} catch (err) {

				} finally {

				}

			},
			//点击编辑资料
			customerInfoEdit() {
				this.IsSearch = false
				this.UserInfoVisi = true
				if (this.memberInfo.Gender == 0) {
					this.memberInfo.Gender = ''
				}
				this.UserInfoForm.Sex = this.memberInfo.Gender //性别
				if (this.memberInfo.BirthdayValue == '1900-01-01') {
					this.memberInfo.BirthdayValue = ''
				}

				this.UserInfoForm.Birthday = this.memberInfo.BirthdayValue //生日
				this.UserInfoForm.WxId = this.memberInfo.WeixinId //微信号
				this.UserInfoForm.VipLevel = String(this.memberInfo.MemberVipRoleId) //会员等级
				if (this.memberInfo.VipBelongShopId == 0) {
					this.memberInfo.VipBelongShopId = ''
				}
				this.UserInfoForm.VipDoor = String(this.memberInfo.VipBelongShopId) //所属门店
				this.UserInfoForm.VipLockCustomer = this.memberInfo.LockMallEmployeePhone //锁粉的信息
				this.oldPhone = this.memberInfo.LockMallEmployeePhone
				this.EmployneeInfo.EmployeeName = this.memberInfo.LockMallEmployeeName
				this.EmployneeInfo.ShopNameShow = this.memberInfo.LockMallEmployeeShopName
				this.EmployneeInfo.WxHeadUrl = this.memberInfo.LockMallEmployeeWxHeadUrl
				this.UserInfoForm.LockMallEmployeeId = this.memberInfo.LockMallEmployeeId //锁粉Id
				if (this.UserInfoForm.LockMallEmployeeId == 0) {
					this.IsSearch = false
				}
				if (this.memberInfo.ProvinceId && this.memberInfo.CityId) {
					// this.AddressArr.push()
					this.AddressArr = [String(this.memberInfo.ProvinceId), String(this.memberInfo.CityId)]
				} else {
					this.AddressArr = []
				}
				console.log(this.AddressArr, '地址数据')
			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('http://cdn.dkycn.cn/json/citylist-include.json', {})
					.then(function(response) {
						that.wePlacelist = response.data
						that.wePlacelist.map(item => {
							if (item.child.length) {
								item.child.map(items => {
									if (items.child.length) {
										// items.child = []
										delete items.child
									}
								})
							}

						})
					})
					.catch(function(error) {})

			},
			//改变地址
			handleAddressChange() {
				this.UserInfoForm.ProvinceId = this.AddressArr[0]
				this.UserInfoForm.CityId = this.AddressArr[1]
				this.wePlacelist.map(item => {
					if (item.id == this.UserInfoForm.ProvinceId) {
						this.UserInfoForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.UserInfoForm.CityId) {
								this.UserInfoForm.City = items.name
							}
						})
					}
				})
			},
			//获取会员等级
			async getUserlevels() {
				try {
					let result = await MemberUserlevels()
					this.VipLevelist = result.Result
					console.log(this.VipLevelist, '等级list')
				} catch (err) {

				} finally {

				}
			},
			//获取所有标签名称
			async getMemberTaglists() {
				try {
					let data = {
						TagType: 0
					}
					let result = await MemberTaglists(data)
					if (result.IsSuccess) {
						this.LabelNames = result.Result.Results
					}
				} catch (err) {

				} finally {

				}
			},
			async getfilterBoxList() {
				try {
					let result = await filterBoxList({})
					this.Distrilevelist = result.Result
				} catch (err) {

				} finally {

				}
			},
			unique(arr) {

				const res = new Map();
				return arr.filter((arr) => !res.has(arr.Text) && res.set(arr.Text, 1))
			},
			//初始化接口
			async startData() {
				this.loading = true
				try {
					let result = await MemberdetailInfo({
						Id: this.MemberId
					})
					if (result.IsSuccess) {
						this.memberInfo = result.Result
						// 获取被选中的标签
						this.SelectLabels = this.unique(result.Result.MemberTagList)
					}
				} catch (err) {
					if (err == '客户不存在') {
						this.$router.push({
							path: "/Customer/CustomerSearch"
						})
					}
				} finally {
					this.loading = false
				}
			},
			authCustomer() {
				this.ShowAuthCustomer = true
			},
			async contact() {
				//被授权
				// if (this.memberInfo.OpenId) {
				const res = await XJChatGetLoginCert()
				// window.open('http://kftest.xiaojiankeji.com/page/#/service?cert='+res.LoginCert)
				if (process.env.NODE_ENV == 'production') {
					window.open('https://kftest.xiaojiankeji.com/page/#/service?cert=' + res.LoginCert)
				} else {
					window.open('http://localhost:8008/#/service?cert=' + res.LoginCert)
				}

				// } else {
				// 	this.$message({showClose: true,
				// 		message: '未获取到客户微信授权信息，暂不支持发起对话',
				// 		type: 'error'
				// 	});
				// }

			},
			//改变积分
			AddScore() {
				this.ScoreForm.Num = ''
				this.ScoreForm.Cause = ''
				this.Scorevisi = true
				this.$nextTick(function() {
					this.$refs.ScoreForm.clearValidate()
				})
			},
			ReduceScore() {
				this.ReduceForm.Num = ''
				this.ReduceForm.Cause = ''
				this.Reducevisi = true
				this.$nextTick(function() {
					this.$refs.ReduceForm.clearValidate()
				})
			},
			//积分清零
			customerClearPoints() {
				this.Scorezerovisi = true
				this.ScorezeroForm.Cause = ''
			},
			handleClose(index) {
				this.SelectLabels.splice(index, 1);
			},

			//扣减积分
			ReduceSure(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.Reducscoreloading = true
						try {
							if (this.ReduceForm.Num > 100000000) {
								this.$message({
									showClose: true,
									message: '扣减积分不能超过100000000',
									type: 'error'
								});
								this.Reducscoreloading = false
								return
							}
							let data = {
								Id: this.MemberId,
								ChangePoint: '-' + this.ReduceForm.Num,
								Reason: this.ReduceForm.Cause
							}
							let result = await Changesingelpoint(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success'
								});
								this.Reducevisi = false
								this.startData()
							}

						} catch (err) {

						} finally {
							this.Reducscoreloading = false
						}
					} else {
						return false;
					}
				});
			},
			// 赠送积分（限制积分数量）
			submitaddSure(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.Addscoreloading = true
						try {
							if (this.ScoreForm.Num > 100000000) {
								this.$message({
									showClose: true,
									message: '增加积分不能超过100000000',
									type: 'error'
								});
								this.Addscoreloading = false
								return
							}
							let data = {
								Id: this.MemberId,
								ChangePoint: this.ScoreForm.Num,
								Reason: this.ScoreForm.Cause
							}
							let result = await Changesingelpoint(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success'
								});
								this.Scorevisi = false
								this.startData()
							}

						} catch (err) {

						} finally {
							this.Addscoreloading = false
						}
					} else {
						return false;
					}
				})
			},
			// 编辑资料
			SetUserInfo(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			AuthUserInfo() {

			},
			//点击返现
			async showCashPop() {
				this.ShowCashloading = true
				try {
					let data = {
						Id: this.MemberId,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await cashbackOrderList(data)
					if (result.IsSuccess) {
						this.isShowCashPop = true
						this.cashData = result.Result.Results || [];
						this.Total = result.Result.Total;
					}
				} catch (err) {
					// this.isShowCashPop = true
				} finally {
					this.ShowCashloading = false
				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.showCashPop();
			},
			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.showCashPop();
			},
		}
	}
</script>

<style lang="less">
	.customerDetail {
		.elTags .el-tag--medium .el-icon-close {
			-webkit-transform: scale(1) !important;
			transform: scale(1) !important;
		}

		.elTags .el-tag {
			display: flex;
			justify-content: center;
			height: 50px !important;
			align-items: center;
			position: relative;
		}

		.elTags .el-tag .tagStyle {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			white-space: normal;
			word-wrap: break-word;
			word-break: break-all;
		}

		.elTags .el-tag .el-tag__close {
			position: absolute;
			color: #666 !important;
			top: -8px !important;
			color: #fff !important;
			background: #ddd !important;
			right: -5px !important;
		}

		.selectBox {
			font-size: 14px;
			flex-wrap: wrap;

			.selectItem {
				vertical-align: middle;
				width: 33.3%;
				margin-bottom: 15px;
				font-size: 14px;

				.checkI {
					margin-right: 15px;
				}
			}
		}

		.displayCommon {
			display: -webkit-box;
			/* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
			display: -moz-box;
			/* Firefox 17- */
			display: -webkit-flex;
			/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
			display: -moz-flex;
			/* Firefox 18+ */
			display: -ms-flexbox;
			/* IE 10 */
			display: flex;
			/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
		}


		// .el-select .el-input {
		// 	width: 300px !important;
		// }

		// .check {
		// 	position: absolute;
		// 	right: 0;
		// 	top: -38px;
		// }

		// .el-checkbox__inner {
		// 	width: 20px !important;
		// 	height: 20px !important;
		// }

		// .el-checkbox__inner::after {
		// 	left: 8px !important;
		// 	top: 3px !important;
		// }
	}
</style>
